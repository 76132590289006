import React, {Component} from "react";
import {Col, Grid, Row, Table} from "react-bootstrap";

import {Card} from "components/Card/Card.jsx";
import {billThArray} from "variables/Variables.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import firebase from "firebase";
import Select from "react-select";
import {withRouter} from "react-router";

class AddBill extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patientId: props.match.params.patientId,
            patientDetails: {},
            products: {},
            invoice: {},
            hid: localStorage.getItem('hospitalId'),
        };

        this.dbRef = firebase.firestore();
        this.hospitalRef = this.dbRef.collection('hospitals').doc(this.state.hid);

        this.dbRef.collection('patients').doc(this.state.patientId).get().then((patientDetails) => {
            this.setState({patientDetails: patientDetails.data()});
            let curInvoice = this.state.invoice;
            patientDetails.data().selectedTests.forEach((id) => {
                curInvoice[id] = {
                    qty: 1,
                    type: 'test'
                }
            });
            this.setState({invoice: curInvoice});
        }).catch((e) => {
            alert('Error Fetching Pre-filled Data');
        });

        this.hospitalRef.collection('tests').get().then((tests) => {
            if (tests.empty) {
                console.log('No Tests Found');
                return;
            }
            let tstList = {};
            tests.forEach(test => {
                tstList[test.id] = {name: test.data().name, rate: test.data().price};
            });
            this.setState({products: tstList});
        }).catch((e) => {
            alert(`Error Fetching Tests List ${e.stack}`);
        });

        this.changeQty = function (id, value) {
            if (value < 1) return;
            let curInvoice = this.state.invoice;
            curInvoice[id].qty = value;
            this.setState({invoice: curInvoice})
        }.bind(this);

        this.remove = function (id) {
            let curInvoice = this.state.invoice;
            delete curInvoice[id];
            this.setState({invoice: curInvoice})
        }.bind(this);

        this.addProduct = function (id) {
            let curInvoice = this.state.invoice;
            curInvoice[id] = {qty: 1, type: 'test'};
            this.setState({invoice: curInvoice})
        }.bind(this);

        this.generateBill = function () {
            let error = false;
            let amount = 0;
            let curInvoice = this.state.invoice;
            Object.keys(curInvoice).forEach((id) => {
                if (!this.state.products[id]) {
                    error = true;
                    return;
                }
                curInvoice[id].rate = this.state.products[id].rate;
                amount += curInvoice[id].rate * curInvoice[id].qty;
            });
            if(error) {
                alert('Invoice not fully loaded');
                return;
            }
            this.hospitalRef.collection('invoices').add({
                invoiceDate: new Date(),
                generatedBy: firebase.auth().currentUser.uid,
                patientId: this.state.patientId,
                items: curInvoice,
                amount
            }).then((invoiceRef)=>{
                let count = 0;
                Object.keys(curInvoice).forEach((testId)=>{
                    this.dbRef.collection('lab_reports').add({
                        billId: invoiceRef.id,
                        createDate: new Date(),
                        hid: this.state.hid,
                        pid: this.state.patientId,
                        sampleId: this.state.patientDetails.sampleId,
                        testId,
                        status: 'Pending',
                    }).then((ref)=>{
                        count++;
                        if (count===Object.keys(curInvoice).length){
                            alert(`Invoice Generated with ID: ${invoiceRef.id}`);
                            props.history.push(`/path/patients/view/${this.state.patientId}`)
                        }
                    });
                });
            }).catch((e)=>{
                alert(`Error Occurred: ${e.stack}`);
            });
        }.bind(this);
    }

    render() {
        let amount = 0;
        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title={`Add Bill (${this.state.patientDetails.name || "Loading"})`}
                                category={`Sample ID: ${this.state.patientDetails.sampleId || 'Loading'}`}
                                content={
                                    <div>
                                        <Select
                                            name="services"
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            options={Object.keys(this.state.products).map((id) => {
                                                return {value: id, label: this.state.products[id].name};
                                            })}
                                            onChange={(e) => {
                                                this.addProduct(e.value)
                                            }}
                                        />
                                        <Table striped hover>
                                            <thead>
                                            <tr>
                                                {billThArray.map((prop, key) => {
                                                    return <th key={key}>{prop}</th>;
                                                })}
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {Object.keys(this.state.invoice).map((id, sno) => {
                                                amount += this.state.products[id] ? this.state.products[id].rate * this.state.invoice[id].qty : 0;
                                                return (
                                                    <tr key={id}>
                                                        <td key={0}>{sno + 1}</td>
                                                        <td key={1}>{this.state.products[id] ? this.state.products[id].name : "Loading"}</td>
                                                        <td key={2}>{this.state.products[id] ? this.state.products[id].rate : "Loading"}</td>
                                                        <td key={3}>1</td>
                                                        <td key={4}>{this.state.products[id] ? this.state.products[id].rate * this.state.invoice[id].qty : "Loading"}</td>
                                                        <td key={4}><Button onClick={() => {
                                                            this.remove(id)
                                                        }}>Remove</Button></td>
                                                    </tr>
                                                );
                                            })}
                                            <tr>
                                                <td key={0}/>
                                                <td key={1}/>
                                                <td key={2}/>
                                                <td key={3}>Total Amount</td>
                                                <td key={4}>{amount} INR</td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                        <Button onClick={this.generateBill} bsStyle="info" pullRight fill>
                                            Save bill
                                        </Button>
                                        <div className="clearfix"/>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default withRouter(AddBill);
