import React, {Component, Fragment} from "react";
import {Col, Grid, Row, Table} from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import firebase from "firebase";
import {Link} from "react-router-dom";
import {withRouter} from "react-router";

class PatientList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patientId: props.match.params.patientId,
            pendingTests: [],
            details: {name: 'Loading', email: 'Loading'},
        };

        this.dbRef = firebase.firestore();

        this.dbRef.collection('patients').doc(this.state.patientId).get().then((patient) => {
            this.setState({details: patient.data()})
        }).catch((e) => {
            alert(`Error Getting Patient Detail ${e.stack}`);
        });

        this.dbRef.collection('lab_reports').where('hid', '==', localStorage.getItem('hospitalId')).where('pid', '==', this.state.patientId).where('status', '==', 'Pending').get().then((snapshot) => {
            if (snapshot.empty) {
                // alert('No Pending Test Available.');
                return;
            }

            let testList = [];
            snapshot.forEach(test => {
                testList.push({...test.data(), id: test.id});
            });
            this.setState({pendingTests: testList});
        }).catch((e) => {
            alert(`Error Getting Pending Test ${e.stack}`);
        });
    }

    render() {
        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title="Patient Detail"
                                category={this.state.details.name}
                                ctTableFullWidth
                                ctTableResponsive
                                content={
                                    <Fragment>
                                        <Table striped hover>
                                            <thead>
                                            <tr>
                                                <th key='0'>Field</th>
                                                <th key='1'>Value</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr key='0'>
                                                <td key={0}>Name</td>
                                                <td key={1}>{this.state.details.name || "Loading"}</td>
                                            </tr>
                                            <tr key='1'>
                                                <td key={0}>Age</td>
                                                <td key={1}>{this.state.details.age || "Loading"}</td>
                                            </tr>
                                            <tr key='2'>
                                                <td key={0}>Gender</td>
                                                <td key={1}>{this.state.details.gender || "Loading"}</td>
                                            </tr>
                                            <tr key='3'>
                                                <td key={0}>Phone</td>
                                                <td key={1}>{this.state.details.phone || "Loading"}</td>
                                            </tr>
                                            <tr key='4'>
                                                <td key={0}>Email</td>
                                                <td key={1}>{this.state.details.email}</td>
                                            </tr>
                                            <tr key='5'>
                                                <td key={0}>Address</td>
                                                <td key={1}>{this.state.details.address || "Loading"}</td>
                                            </tr>
                                            <tr key='6'>
                                                <td key={0}>Registered on</td>
                                                <td key={1}>{this.state.details.registrationDate ? new Date(this.state.details.registrationDate.seconds).toString() : "Loading"}</td>
                                            </tr>
                                            <tr key='7'>
                                                <td key={0}>Pending Tests</td>
                                                <td key={1}>
                                                    {this.state.pendingTests.map(test => {
                                                        return (
                                                            <Button
                                                                onClick={() => {
                                                                    this.props.history.push(`/path/test/report/${test.id}`)
                                                                }}
                                                            >
                                                                {test.id}
                                                            </Button>
                                                        )
                                                    })}
                                                    {this.state.pendingTests.length === 0 ? "None" : null}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                        <Link to={`/path/register/edit/${this.state.patientId}`}>
                                            <Button onClick={this.generateBill} bsStyle="info" pullRight fill>
                                                Edit Details
                                            </Button>
                                        </Link>
                                        <Link to={`/path/billing/view/${this.state.patientId}`}>
                                            <Button onClick={this.generateBill} bsStyle="info" pullRight fill>
                                                View Bills
                                            </Button>
                                        </Link>
                                        <Link to={`/path/billing/add/${this.state.patientId}`}>
                                            <Button onClick={this.generateBill} bsStyle="info" pullRight fill>
                                                Add Bill
                                            </Button>
                                        </Link>
                                        <div className="clearfix"/>
                                    </Fragment>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default withRouter(PatientList);
