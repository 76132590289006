import React, {Component} from "react";
import {ControlLabel, Row, Table} from "react-bootstrap";

import {Card} from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Input from "@material-ui/core/Input";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import firebase from "firebase";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Paper from "@material-ui/core/Paper"
import Box from '@material-ui/core/Box';

class Services extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabIndex: 0,
            services: [],
            name: '',
            description: '',
            price: 0,
        };
        this.hospitalRef = firebase.firestore().collection('hospitals').doc(localStorage.getItem('hospitalId'));

        this.hospitalRef.collection('services').get().then((snapshot)=>{
            if (snapshot.empty) {
                alert('No Services Data Available.');
                return;
            }

            let serviceList = [];
            snapshot.forEach(service => {
                serviceList.push({...service.data(), id: service.id});
            });
            this.setState({services: serviceList});
        });
        this.submit = this.submit.bind(this);
    }

    submit() {
        let {name, description, price} = this.state;
        if (!name || name === '') {
            alert('Fill Name');
            return;
        }
        if (!price || price === 0) {
            alert('Fill Price');
            return;
        }
        if (!description || description === '') {
            alert('Fill Description');
            return;
        }
        this.hospitalRef.collection('services').add({
            name, description, price
        }).then(() => {
            alert('Successfully Added');
        }).catch((e) => {
            alert(`Error while updating Service: ${e.stack}`);
        })

    }

    render() {
        return (
            <div className="content" style={{padding:50}}>
            <Box display="flex" justifyContent="center" style={{marginBottom:30, marginTop: -15}}>
            <Paper elevation={4} square>
                <Tabs
                    value={this.state.tabIndex}
                    onChange={() => {
                        this.setState({
                            tabIndex: this.state.tabIndex === 0 ? 1 : 0
                        })
                    }}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <Tab style={{fontSize: 10}} label="Add Services"/>
                    <Tab style={{fontSize: 10}} label="List Services"/>
                </Tabs>
            </Paper>
            </Box>
                {
                    this.state.tabIndex === 0 ?
                        <Card
                            title=""
                            content={
                                <div style={{padding: 15}}>
                                    <Row>
                                        <div className="col-md-12" style={{marginBottom: 15}}>
                                            <ControlLabel style={{color: "#000000"}}
                                            >
                                            <b>
                                            Name
                                            </b>
                                            </ControlLabel><br/>
                                            <Input
                                                style={{fontSize:15}}
                                                value={this.state.name}
                                                onChange={(e) => {
                                                    this.setState({name: e.target.value})
                                                }}
                                                fullWidth
                                                type="text"
                                                placeholder=""
                                            />
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="col-md-4">
                                            <ControlLabel style={{color: "#000000"}}>
                                            <b>
                                            Price
                                            </b>
                                            </ControlLabel><br/>
                                            <Input
                                                style={{fontSize:15}}
                                                value={this.state.price}
                                                onChange={(e) => {
                                                    this.setState({price: e.target.value})
                                                }}
                                                fullWidth
                                                type="number"
                                                placeholder=""
                                            />
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="col-md-12" style={{marginTop:15}}>
                                            <ControlLabel style={{color: "#000000"}}>
                                            <b>
                                            Details
                                            </b>
                                            </ControlLabel><br/>
                                            <TextareaAutosize
                                                value={this.state.description}
                                                onChange={(e) => {
                                                    this.setState({description: e.target.value})
                                                }}
                                                style={{fontSize:15, height: 100, width: '100%'}}
                                                type="textarea"
                                                placeholder=""
                                            />
                                        </div>
                                    </Row>
                                    <Button
                                        style={{marginTop: 20, marginBottom: -20}}
                                        bsStyle="info"
                                        pullRight
                                        fill
                                        onClick={this.submit}
                                    >
                                        Add Service
                                    </Button>
                                    <div className="clearfix"/>
                                </div>
                            }
                        />
                        :
                        <Card
                            title="Inventory List"
                            content={
                                <Table striped hover>
                                    <thead>
                                    <tr>
                                        <th key='0'>S.No.</th>
                                        <th key='1'>Inventory ID</th>
                                        <th key='2'>Name</th>
                                        <th key='3'>Price</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.services.map((service, sno) => {
                                            return (
                                                <tr onClick={() => {
                                                    this.props.history.push(`/admin/services/edit/${service.id}`);
                                                }}
                                                    key={sno}
                                                >
                                                    <td key={0}>{sno + 1}</td>
                                                    <td key={1}>{service.id}</td>
                                                    <td key={2}>{service.name}</td>
                                                    <td key={3}>{service.price}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </Table>
                            }
                        />
                }
            </div>
        )
    }
}

export default Services;
