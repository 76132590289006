import React, {Component} from "react";
import {Col, ControlLabel, Grid, Row, Table} from "react-bootstrap";

import {Card} from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import firebase from "firebase";
import Input from "@material-ui/core/Input";
import {withRouter} from "react-router";
import Checkbox from "@material-ui/core/Checkbox";

class AddTest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            testName: '',
            testPrice: '',
            remarks: '',
            sampleType: '',
            isBool: false,
            prm_boolean: false,
            parameters: {},
            invoice: {},
        };

        this.dbRef = firebase.firestore();
        this.hospitalRef = this.dbRef.collection('hospitals').doc(localStorage.getItem('hospitalId'));

        this.remove = function (parameter) {
            let parameters = this.state.parameters;
            delete parameters[parameter];
            this.setState({parameters})
        }.bind(this);

        this.addParameter = function () {
            let parameters = this.state.parameters;
            parameters[this.state.prm_name] = {
                name: this.state.prm_name,
                unit: this.state.prm_unit,
                max: this.state.prm_max,
                min: this.state.prm_min,
                boolean: this.state.prm_boolean,
            };
            this.setState({parameters})
        }.bind(this);

        this.saveTest = function () {
            this.hospitalRef.collection('tests').add({
                name: this.state.testName,
                price: this.state.testPrice,
                remarks: this.state.remarks,
                sampleType: this.state.sampleType,
                isBool: this.state.isBool,
                parameters: Object.keys(this.state.parameters).map((parameter)=>{
                    return this.state.parameters[parameter];
                })
            }).then((testRef)=>{
                alert(`Test Added with ID: ${testRef.id}`);
                props.history.push(`/path/test`)
            }).catch((e)=>{
                alert(`Error Occurred: ${e.stack}`);
            });
        }.bind(this);
    }

    render() {
        return <div className="content">
            <Grid fluid>
                <Row>
                    <Col md={12}>
                        <Card
                            title={`Add Test`}
                            content={
                                <div>
                                    <ControlLabel>Test Name</ControlLabel><br/>
                                    <Input
                                        value={this.state.testName}
                                        onChange={(e) => {
                                            this.setState({testName: e.target.value})
                                        }}
                                        fullWidth
                                        type="text"
                                        placeholder="Test Name"
                                    /><br/><br/>
                                    <ControlLabel>Price</ControlLabel><br/>
                                    <Input
                                        value={this.state.testPrice}
                                        onChange={(e) => {
                                            this.setState({testPrice: e.target.value})
                                        }}
                                        fullWidth
                                        type="number"
                                        placeholder="Price"
                                    /><br/><br/>
                                    <ControlLabel>Remarks/Caution</ControlLabel><br/>
                                    <Input
                                        value={this.state.remarks}
                                        onChange={(e) => {
                                            this.setState({remarks: e.target.value})
                                        }}
                                        fullWidth
                                        type="text"
                                        placeholder="Remarks/Caution"
                                    /><br/><br/>
                                    <ControlLabel>Sample Type</ControlLabel><br/>
                                    <Input
                                        value={this.state.sampleType}
                                        onChange={(e) => {
                                            this.setState({sampleType: e.target.value})
                                        }}
                                        fullWidth
                                        type="text"
                                        placeholder="Sample Type"
                                    /><br/><br/>
                                    <ControlLabel>Is Boolean</ControlLabel><br/>
                                    <Checkbox
                                        checked={this.state.isBool}
                                        onChange={(e)=>{
                                            this.setState({isBool: !this.state.isBool})
                                        }}
                                        color="primary"
                                    /><br/><br/>
                                    <ControlLabel>Parameters</ControlLabel><br/>
                                    <Table striped hover>
                                        <thead>
                                        <tr>
                                            <th key={0}>Name</th>
                                            <th key={2}>Unit</th>
                                            <th key={3}>Min value</th>
                                            <th key={4}>Max value</th>
                                            <th key={6}>Boolean</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {Object.keys(this.state.parameters).map((parameter, sno) => {
                                            return <tr key={parameter}>
                                                <td key={0}>{this.state.parameters[parameter].name}</td>
                                                <td key={2}>{this.state.parameters[parameter].unit}</td>
                                                <td key={3}>{this.state.parameters[parameter].max}</td>
                                                <td key={4}>{this.state.parameters[parameter].min}</td>
                                                <td key={6}><Checkbox
                                                    checked={this.state.parameters[parameter].boolean}
                                                    color="primary"
                                                /></td>
                                                <td key={8}><Button onClick={() => {
                                                    this.remove(parameter)
                                                }}>Remove</Button></td>
                                            </tr>;
                                        })}
                                        <tr>
                                            <td key={0}><Input
                                                placeholder={`Name`}
                                                type="text"
                                                onChange={(e) => {
                                                    this.setState({prm_name: e.target.value})
                                                }}
                                                // value={this.state.invoice[id].qty}
                                            /></td>
                                            <td key={2}><Input
                                                placeholder={`Unit`}
                                                type="text"
                                                onChange={(e) => {
                                                    this.setState({prm_unit: e.target.value})
                                                }}
                                                // value={this.state.invoice[id].qty}
                                            /></td>
                                            <td key={3}><Input
                                                placeholder={`Min Value`}
                                                type="number"
                                                onChange={(e) => {
                                                    this.setState({prm_min: e.target.value})
                                                }}
                                                // value={this.state.invoice[id].qty}
                                            /></td>
                                            <td key={4}><Input
                                                placeholder={`Max Value`}
                                                type="number"
                                                onChange={(e) => {
                                                    this.setState({prm_max: e.target.value})
                                                }}
                                                // value={this.state.invoice[id].qty}
                                            /></td>
                                            <td key={6}><Checkbox
                                                checked={this.state.prm_boolean}
                                                onChange={(e)=>{
                                                    this.setState({prm_boolean: !this.state.prm_boolean})
                                                }}
                                                color="primary"
                                            /></td>
                                            <td key={8}><Button onClick={() => {
                                                this.addParameter()
                                            }}>Add</Button></td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                    <Button onClick={this.saveTest} bsStyle="info" pullRight fill>
                                        Save test
                                    </Button>
                                    <div className="clearfix"/>
                                </div>
                            }
                        />
                    </Col>
                </Row>
            </Grid>
        </div>;
    }
}

export default withRouter(AddTest);
