import React, {Component} from "react";
import {HashRouter, Redirect, Route, Switch} from "react-router-dom";
import firebase from "firebase";
import AdminLayout from "./layouts/Admin";
import PathologyLayout from "./layouts/Pathology";
import RadioLayout from "./layouts/Radio";
import Login from "./layouts/Login";

export default class Farmako extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn : false,
        };
        firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                firebase.firestore().collection('users').doc(user.uid).get().then((doc) => {
                    localStorage.setItem('hospitalId',doc.data().hospital);
                    if(!this.state.isLoggedIn) this.setState({isLoggedIn: true});
                });
            } else {
                localStorage.clear();
                if(this.state.isLoggedIn) this.setState({isLoggedIn: false});
            }
        }.bind(this));
    }
    render() {
        let {isLoggedIn} = this.state;
        return (
            <HashRouter>
                <Switch>
                    <Route path="/admin" render={ props => isLoggedIn?<AdminLayout {...props} />:<Login /> } />
                    <Route path="/path" render={ props => isLoggedIn?<PathologyLayout {...props} />:<Login /> } />
                    <Route path="/radio" render={ props => isLoggedIn?<RadioLayout {...props} />:<Login /> } />
                    <Redirect from="/" to="/admin/register" />
                </Switch>
            </HashRouter>
        )
    }
}
