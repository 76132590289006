/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {Component} from "react";
import {Col, Grid, Row, Table} from "react-bootstrap";

import {Card} from "components/Card/Card.jsx";
import firebase from "firebase";
import {withRouter} from "react-router";

class Billing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bills: [],
            patientName: {},
            staffName: {},
        };

        this.dbRef = firebase.firestore();

        this.hospitalRef = firebase.firestore().collection('hospitals').doc(localStorage.getItem('hospitalId'));

        this.hospitalRef.collection('invoices').get().then(async (invoices) => {
            if (invoices.empty) {
                console.log('No matching invoices.');
                return;
            }

            let bills = [];

            invoices.forEach(invoice => {
                bills.push({...invoice.data(), id: invoice.id});
            });

            this.setState({bills});
            for (const bill of this.state.bills) {
                if (!this.state.patientName[bill.patientId]) this.state.patientName[bill.patientId] = ((await this.dbRef.collection('patients').doc(bill.patientId).get()).data() || {}).name || "";
                if (!this.state.staffName[bill.generatedBy]) this.state.staffName[bill.generatedBy] = ((await this.hospitalRef.collection('user_details').doc(bill.generatedBy).get()).data() || {}).name || "";
                this.setState({});
            }
        }).catch((e) => {
            alert(`Error Getting Patient Detail ${e.stack}`);
        });
    }

    render() {
        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title={`Billing Details`}
                                content={
                                    <div>
                                        <Table striped hover>
                                            <thead>
                                            <tr>
                                                <th key='0'>S.No</th>
                                                <th key='1'>Patient</th>
                                                <th key='2'>Date/Time</th>
                                                <th key='3'>Generated By</th>
                                                <th key='4'>Amount</th>
                                                <th key='5'>Paid</th>
                                                <th key='6'>Unpaid</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.bills.map((invoice, sno) => {
                                                return (
                                                    <tr onClick={() => this.props.history.push(`/admin/billing/bill/${invoice.id}`)}
                                                        key={sno}>
                                                        <td key='0'>{sno + 1}</td>
                                                        <td key='1'>{this.state.patientName[invoice.patientId] || "Loading"}</td>
                                                        <td key='2'>{invoice.invoiceDate.toDate().toUTCString()}</td>
                                                        <td key='3'>{this.state.staffName[invoice.generatedBy] || "Loading"}</td>
                                                        <td key='4'>{invoice.amount}</td>
                                                        <td key='5'>{invoice.amount}</td>
                                                        <td key='6'>0</td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </Table>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default withRouter(Billing);
