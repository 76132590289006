import React, {Component, Fragment} from "react";
import {Col, ControlLabel, FormGroup, Grid, Row} from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import MUIButton from '@material-ui/core/Button';
import Select from 'react-select/creatable'
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import firebase from "firebase";
import Input from "@material-ui/core/Input";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import {withRouter} from 'react-router-dom'
import LinearProgress from "@material-ui/core/LinearProgress";
import Modal from "@material-ui/core/Modal";

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pid: '',
            modal: false,
            prepopulateData: [],
            hid: localStorage.getItem('hospitalId'),
            doctors: [],
            tests: {},
            sampleId: '',
            selectedDoctor: '',
            phone: '',
            name: '',
            age: '',
            gender: 'male',
            email: '',
            address: '',
            selectedTests: [],
        };

        this.prepopulate = function (phone) {
            this.setState({modal: true});
            this.dbRef.collection('patients').where('phone', '==', phone).get().then((patients) => {
                if (patients.empty) {
                    this.setState({modal: false});
                    return;
                }
                let patientList = [];
                patients.forEach(patient => {
                    patientList.push({...patient.data(), id: patient.id});
                });
                this.setState({prepopulateData: patientList})
            }).catch((e) => {
                alert('Error Fetching Patient List');
            });
        }.bind(this);

        this.submit = function () {
            if (this.state.pid === '') {
                this.dbRef.collection('patients').add({
                    registrationDate: new Date(),
                    selectedDoctor: this.state.selectedDoctor,
                    phone: this.state.phone,
                    name: this.state.name,
                    age: this.state.age,
                    gender: this.state.gender,
                    email: this.state.email,
                    address: this.state.address,
                    sampleId: this.state.sampleId,
                    selectedTests: this.state.selectedTests,
                }).then((ref) => {
                    this.addTestToPatient(ref.id);
                }).catch((e) => {
                    alert(`Error while registering ${e.stack}`);
                })
            } else {
                this.addTestToPatient(this.state.pid);
            }
        }.bind(this);

        this.addTestToPatient = function (pid) {
            let count = 0;
            this.state.selectedTests.forEach(test => {
                this.dbRef.collection('lab_reports').add({
                    billId: '',
                    createDate: new Date(),
                    hid: this.state.hid,
                    pid: pid,
                    sampleId: this.state.sampleId,
                    testId: test,
                    status: 'Pending',
                }).then((testref) => {
                    count++;
                    if (count === this.state.selectedTests.length) {
                        alert(`Patient Registered with ID: ${pid}`);
                        props.history.push(`/path/patients/view/${pid}`)
                    }
                });
            });
        }.bind(this);

        this.addTest = function (id) {
            let temp = this.state.selectedTests;
            if (temp.indexOf(id) === -1) temp.push(id);
            this.setState({selectedTests: temp});
        }.bind(this);

        this.removeTest = function (id) {
            let temp = this.state.selectedTests;
            if (temp.indexOf(id) !== -1) temp.splice(temp.indexOf(id), 1);
            this.setState({selectedTests: temp});
        }.bind(this);

        this.dbRef = firebase.firestore();
        this.hospitalRef = this.dbRef.collection('hospitals').doc(this.state.hid);

        this.hospitalRef.collection('user_details').where('type', '==', 'doc').get().then((doctors) => {
            if (doctors.empty) {
                console.log('No Doctors Found');
                return;
            }
            let docList = [];
            doctors.forEach(doc => {
                docList.push({value: doc.data().name, label: doc.data().name});
            });
            this.setState({doctors: docList});
        }).catch((e) => {
            alert('Error Fetching Doctor List');
        });
        this.hospitalRef.collection('tests').get().then((tests) => {
            if (tests.empty) {
                console.log('No Tests Found');
                return;
            }
            let testList = {};
            tests.forEach(test => {
                testList[test.id] = test.data().name;
            });
            this.setState({tests: testList});
        }).catch((e) => {
            alert(`Error Fetching Tests List ${e.stack}`);
        });
    }

    render() {
        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Paper elevation={8} style={{padding: 20}}>
                                <Row>
                                    <div className="col-md-6" style={{marginBottom: 10}}>
                                        <div style={{marginBottom: 5}}>
                                            <b>
                                                Referred by (Doctor)
                                            </b>
                                        </div>
                                        <Select
                                            options={this.state.doctors}
                                            onChange={(e) => {
                                                this.setState({selectedDoctor: e.value})
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <ControlLabel>Sample ID</ControlLabel><br/>
                                        <Input
                                            value={this.state.sampleId}
                                            onChange={(e) => {
                                                this.setState({sampleId: e.target.value})
                                            }}
                                            fullWidth
                                            type="text"
                                            placeholder="e.g. SMP001"
                                        />
                                    </div>
                                </Row>
                                <Row>
                                    <div className="col-md-6" style={{marginBottom: 10}}>
                                        <ControlLabel>Phone Number</ControlLabel><br/>
                                        <Input
                                            style={{fontSize: 15}}
                                            value={this.state.phone}
                                            onChange={(e) => {
                                                if (e.target.value.length > 10) {
                                                    return;
                                                }
                                                this.setState({phone: e.target.value});
                                                if (e.target.value.length === 10) {
                                                    this.prepopulate(e.target.value);
                                                }
                                            }}
                                            fullWidth
                                            type="number"
                                            placeholder="9999999999"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <ControlLabel>Name</ControlLabel><br/>
                                        <Input
                                            value={this.state.name}
                                            disabled={this.state.pid !== ''}
                                            onChange={(e) => {
                                                this.setState({name: e.target.value})
                                            }}
                                            fullWidth
                                            type="text"
                                            placeholder="Name"
                                        />
                                    </div>
                                </Row>
                                <Row>
                                    <div className="col-md-6">
                                        <ControlLabel>Age</ControlLabel><br/>
                                        <Input
                                            value={this.state.age}
                                            disabled={this.state.pid !== ''}
                                            onChange={(e) => {
                                                this.setState({age: e.target.value})
                                            }}
                                            fullWidth
                                            type="number"
                                            placeholder="Age"
                                        />
                                    </div>
                                    <div className="col-md-6" style={{marginTop: 15}}>
                                        <div
                                            style={{marginBottom: 5}}
                                        >

                                            <b>
                                                Gender
                                            </b>
                                        </div>
                                        <MUIButton
                                            variant="contained"
                                            color={this.state.gender === 'male' ? 'primary' : ''}
                                            onClick={() => {
                                                if (this.state.pid !== '') return;
                                                this.setState({gender: 'male'})
                                            }}
                                        >
                                            Male
                                        </MUIButton>&nbsp;&nbsp;&nbsp;
                                        <MUIButton
                                            variant="contained"
                                            color={this.state.gender === 'female' ? 'primary' : ''}
                                            onClick={() => {
                                                if (this.state.pid !== '') return;
                                                this.setState({gender: 'female'})
                                            }}
                                        >
                                            Female
                                        </MUIButton>&nbsp;&nbsp;&nbsp;
                                        <MUIButton
                                            variant="contained"
                                            color={this.state.gender === 'others' ? 'primary' : ''}
                                            onClick={() => {
                                                if (this.state.pid !== '') return;
                                                this.setState({gender: 'others'})
                                            }}
                                        >
                                            Others
                                        </MUIButton>
                                    </div>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Paper style={{padding: 10, height: 250}}>
                                            <div
                                                style={{marginBottom: 8}}
                                            >
                                                <b>
                                                    Tests
                                                </b>
                                            </div>
                                            <Paper style={{padding: 10, height: 40}}>
                                                {
                                                    this.state.selectedTests.map((id) => {
                                                        return <Fragment>
                                                            <Chip
                                                                size="small"
                                                                color="primary"
                                                                label={this.state.tests[id]}
                                                                onDelete={() => {
                                                                    this.removeTest(id)
                                                                }}
                                                            />
                                                            &nbsp;&nbsp;&nbsp;
                                                        </Fragment>;
                                                    })
                                                }
                                            </Paper>
                                            <br/>
                                            <Paper style={{padding: 10}}>
                                                <MUIButton
                                                    onClick={() => {
                                                        this.addTest('RhDDKhvAk0rUdvhKgJMA')
                                                    }}
                                                    variant="contained">OPD</MUIButton>&nbsp;&nbsp;&nbsp;
                                                <MUIButton
                                                    onClick={() => {
                                                        this.addTest('RhDDKhvAk0rUdvhKgJMA')
                                                    }}
                                                    variant="contained">Appointment</MUIButton>&nbsp;&nbsp;&nbsp;
                                                <MUIButton
                                                    onClick={() => {
                                                        this.addTest('RhDDKhvAk0rUdvhKgJMA')
                                                    }}
                                                    variant="contained">Hello</MUIButton>&nbsp;&nbsp;&nbsp;
                                                <MUIButton
                                                    onClick={() => {
                                                        this.addTest('RhDDKhvAk0rUdvhKgJMA')
                                                    }}
                                                    variant="contained">World</MUIButton>&nbsp;&nbsp;&nbsp;
                                                <br/>
                                                <br/>
                                                <Select
                                                    name="tests"
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    options={Object.keys(this.state.tests).map((id) => {
                                                        return {value: id, label: this.state.tests[id]};
                                                    })}
                                                    onChange={(e) => {
                                                        this.addTest(e.value)
                                                    }}
                                                />
                                            </Paper>
                                        </Paper>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup controlId="formControlsTextarea"
                                                   style={{marginTop: 20}}
                                        >
                                            <ControlLabel>Email</ControlLabel>
                                            <Input
                                                value={this.state.email}
                                                disabled={this.state.pid !== ''}
                                                onChange={(e) => {
                                                    this.setState({email: e.target.value})
                                                }}
                                                fullWidth
                                                type="text"
                                                placeholder="Email"
                                            />
                                        </FormGroup>
                                        <FormGroup controlId="formControlsTextarea">
                                            <ControlLabel>
                                                <div
                                                    style={{marginBottom: "40"}}
                                                >
                                                    Address
                                                </div>
                                            </ControlLabel><br/>
                                            <TextareaAutosize
                                                value={this.state.address}
                                                disabled={this.state.pid !== ''}
                                                onChange={(e) => {
                                                    this.setState({address: e.target.value})
                                                }}
                                                style={{height: 90, width: '100%'}}
                                                type="textarea"
                                                placeholder=""
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Button
                                    onClick={this.submit}
                                    bsStyle="info"
                                    style={{marginLeft: 18}}
                                    pullRight
                                    fill
                                >
                                    Add Patient
                                </Button>
                                <Button
                                    onClick={this.submit}
                                    bsStyle="info"
                                    pullRight
                                    fill
                                >
                                    Add Patient and Checkout
                                </Button>
                                <div className="clearfix"/>
                            </Paper>
                        </Col>
                    </Row>
                </Grid>
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.modal}
                    onClose={() => {
                    }}
                >
                    {
                        this.state.prepopulateData.length === 0 ?
                            <LinearProgress/>
                            :
                            <div style={{
                                backgroundColor: 'white',
                                width: '40%',
                                margin: 'auto',
                                textAlign: 'center',
                                padding: 5,
                                marginTop: 100,
                                border: "solid 20"
                            }}>
                                <p id="simple-modal-title"
                                   style={{marginBottom: 15, fontSize: 25, marginTop: 10}}>Select the patient below</p>
                                <p id="simple-modal-description">
                                    {this.state.prepopulateData.map(patient => {
                                        return <Fragment>
                                            <Button
                                                onClick={() => {
                                                    this.setState({
                                                        modal: false,
                                                        pid: patient.id,
                                                        phone: patient.phone,
                                                        name: patient.name,
                                                        age: patient.age,
                                                        gender: patient.gender,
                                                        email: patient.email,
                                                        address: patient.address
                                                    })
                                                }}
                                                variant="outlined"
                                            >
                                                {patient.name} ({patient.age} yr(s)/{patient.gender})
                                            </Button>
                                            <br/>
                                        </Fragment>
                                    })}
                                    or<br/>
                                    <Fragment>
                                        <Button style={{marginBottom: 10}} onClick={() => {
                                            this.setState({modal: false, pid: '', prepopulateData: []})
                                        }}>
                                            Add New
                                        </Button>
                                    </Fragment>
                                </p>
                            </div>
                    }
                </Modal>
            </div>
        );
    }
}

export default withRouter(Register);
