import React, {Component} from "react";
import {Col, Grid, Row, Table} from "react-bootstrap";

import {withRouter} from 'react-router-dom'
import Card from "components/Card/Card.jsx";
import firebase from "firebase";

class PatientList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patientList: [],
        };

        this.dbRef = firebase.firestore();
        this.hospitalRef = this.dbRef.collection('hospitals').doc(localStorage.getItem('hospitalId'));

        this.dbRef.collection('patients').orderBy('registrationDate', 'desc').get().then((snapshot)=>{
            if (snapshot.empty) {
                alert('No Appointment Data Available.');
                return;
            }

            let patientList = [];
            snapshot.forEach(patient => {
                patientList.push({
                    id: patient.id,
                    name: patient.data().name,
                    age: patient.data().age,
                    status: patient.data().status,
                    selectedDoctor: patient.data().selectedDoctor,
                });
            });
            this.setState({patientList})
        });
    }
    render() {
        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title="Patient List"
                                category="List of all patients"
                                ctTableFullWidth
                                ctTableResponsive
                                content={
                                    <Table striped hover>
                                        <thead>
                                        <tr>
                                            {
                                                ["Patient ID", "Patient Name", "Age(Years)", "Status", "Doctor"].map((prop, key) => {
                                                    return <th key={key}>{prop}</th>;
                                                })
                                            }
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.state.patientList.map((patient, sno)=>{
                                                return (
                                                    <tr onClick={()=>{
                                                        this.props.history.push(`/path/patients/view/${patient.id}`);
                                                    }}
                                                        key={sno}
                                                    >
                                                        <td key={0}>{patient.id}</td>
                                                        <td key={1}>{patient.name}</td>
                                                        <td key={2}>{patient.age}</td>
                                                        <td key={4}>{patient.status}</td>
                                                        <td key={5}>{patient.selectedDoctor}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </Table>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default withRouter(PatientList);
