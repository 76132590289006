import PatientList from "./views/radio/PatientList.jsx";
import StaffDetails from "./views/StaffDetails";
import Upload from "./views/radio/Upload";
import PatientDetails from "./views/radio/PatientDetails";

const dashboardRoutes = [
    {
        path: "/upload",
        name: "Upload Report",
        icon: "pe-7s-cloud-upload",
        component: Upload,
        layout: "/radio",
        title: "Upload Report"
    },
    {
        path: "/patients/view/:patientId",
        component: PatientDetails,
        layout: "/radio",
        title: "Patients Detail"
    },
    {
        path: "/patients",
        name: "Patients List",
        icon: "pe-7s-note2",
        component: PatientList,
        layout: "/radio",
        title: "Patients List"
    },
    {
        path: "/staffs",
        name: "Staff Details",
        icon: "pe-7s-id",
        component: StaffDetails,
        layout: "/radio",
        title: "Staff Details"
    },
    {
        logout: true,
        name: "Logout",
        icon: "pe-7s-power",
        layout: "/radio"
    }
];

export default dashboardRoutes;
