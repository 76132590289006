import PatientList from "./views/pathology/PatientList.jsx";
import StaffDetails from "./views/StaffDetails";
import Billing from "./views/pathology/Billing";
import Tests from "./views/pathology/Tests";
import Register from "./views/pathology/Register";
import PatientDetails from "./views/pathology/PatientDetails";
import ViewBills from "./views/pathology/ViewBills";
import AddBill from "./views/pathology/AddBill";
import BillDetails from "./views/pathology/BillDetails";
import EditProfile from "./views/pathology/EditProfile";
import AddReport from "./views/pathology/AddReport";

const dashboardRoutes = [
    {
        path: "/register/edit/:patientId",
        component: EditProfile,
        layout: "/path",
        title: "Edit Details"
    },
    {
        path: "/register",
        name: "Patient Registration",
        icon: "pe-7s-user",
        component: Register,
        layout: "/path",
        title: "Patient Registration"
    },
    {
        path: "/patients/view/:patientId",
        component: PatientDetails,
        layout: "/path",
        title: "Patients Detail"
    },
    {
        path: "/patients",
        name: "Patients List",
        icon: "pe-7s-note2",
        component: PatientList,
        layout: "/path",
        title: "Patients List"
    },
    {
        path: "/billing/add/:patientId",
        component: AddBill,
        layout: "/path",
        title: "Add Bill"
    },
    {
        path: "/billing/bill/:billId",
        component: BillDetails,
        layout: "/path",
        title: "Bill Details"
    },
    {
        path: "/billing/view/:patientId",
        component: ViewBills,
        layout: "/path",
        title: "View Bills"
    },
    {
        path: "/billing",
        name: "Billing and Accounting",
        icon: "pe-7s-credit",
        component: Billing,
        layout: "/path",
        title: "Billing and Accounting"
    },
    {
        path: "/test/report/:reportId",
        component: AddReport,
        layout: "/path",
        title: "Add Report"
    },
    {
        path: "/test",
        name: "Tests",
        icon: "pe-7s-plus",
        component: Tests,
        layout: "/path",
        title: "Tests"
    },
    {
        path: "/staffs",
        name: "Staff Details",
        icon: "pe-7s-id",
        component: StaffDetails,
        layout: "/path",
        title: "Staff Details"
    },
    {
        logout: true,
        name: "Logout",
        icon: "pe-7s-power",
        layout: "/path"
    }
];

export default dashboardRoutes;
