import React, {Component, Fragment} from "react";
import {Col, Grid, Row, Table} from "react-bootstrap";

import {Card} from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import firebase from "firebase";
import {Checkbox} from "@material-ui/core";
import Input from "@material-ui/core/Input";
import {withRouter} from 'react-router-dom'

class AddReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reportId: props.match.params.reportId,
            testId: '',
            testDetails: {parameters: []},
            report: {parameters: []},
        };

        this.dbRef = firebase.firestore();
        this.hospitalRef = firebase.firestore().collection('hospitals').doc(localStorage.getItem('hospitalId'));

        this.dbRef.collection('lab_reports').doc(this.state.reportId).get().then((reportDetails) => {
            this.setState({testId: reportDetails.data().testId, pid: reportDetails.data().pid});
        }).then(() => {
            this.hospitalRef.collection('tests').doc(this.state.testId).get().then((testDetails) => {
                this.setState({testDetails: testDetails.data()});
                if (testDetails.data().isBool) this.setState({report: {...this.state.report, result: false}});
            })
        }).catch((e) => {
            alert(`Error Fetching Test Details ${e.stack}`);
        });

        this.submit = function () {
            if(this.state.reportId === '') return;
            this.dbRef.collection('lab_reports').doc(this.state.reportId).set({
                updatedDate: new Date(),
                testDetails: JSON.stringify(this.state.testDetails),
                report: this.state.report,
                status: "Complete",
            }, {merge: true}).then(()=>{
                alert(`Added report successfully`);
                this.props.history.push(`/path/patients/view/${this.state.pid}`)
            })
        }.bind(this);
    }

    render() {
        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title={`Add Report (${this.state.testDetails.name || "Loading"})`}
                                category={this.state.testDetails.remarks || "Loading"}
                                content={
                                    <Fragment>
                                        {!this.state.testDetails.isBool ? null : (
                                            <Fragment>
                                                Report (Is Positive?)
                                                <Checkbox
                                                    checked={this.state.report.result}
                                                    onChange={() => {
                                                        this.setState({
                                                            report: {
                                                                ...this.state.report,
                                                                result: !this.state.report.result
                                                            }
                                                        })
                                                    }}
                                                />
                                            </Fragment>
                                        )}
                                        <Table striped hover>
                                            <thead>
                                            <tr>
                                                {['S. NO.', 'Parameter', 'Range', 'Value'].map((prop, key) => {
                                                    return <th key={key}>{prop}</th>;
                                                })}
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.testDetails.parameters.map((parameter, sno) => {
                                                return (
                                                    parameter.boolean ? <tr key={parameter.name}>
                                                        <td key={0}>{sno + 1}</td>
                                                        <td key={1}>{parameter.name}</td>
                                                        <td key={2}/>
                                                        <td key={3}>
                                                            <Checkbox
                                                                checked={this.state.report.parameters[parameter.name] || false}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        report: {
                                                                            ...this.state.report,
                                                                            parameters: {
                                                                                ...this.state.report.parameters,
                                                                                [parameter.name]: (this.state.report.parameters[parameter.name] === undefined || this.state.report.parameters[parameter.name] === null || this.state.report.parameters[parameter.name] === false)
                                                                            }
                                                                        }
                                                                    })
                                                                }}
                                                                type="number"
                                                                placeholder="value"
                                                            />
                                                        </td>
                                                    </tr> : <tr key={parameter.name}>
                                                        <td key={0}>{sno + 1}</td>
                                                        <td key={1}>{parameter.name}</td>
                                                        <td key={2}>{`${parameter.min} ${parameter.unit} - ${parameter.max} ${parameter.unit}`}</td>
                                                        <td key={3}><Input
                                                            value={this.state.report.parameters[parameter.name]}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    report: {
                                                                        ...this.state.report,
                                                                        parameters: {
                                                                            ...this.state.report.parameters,
                                                                            [parameter.name]: e.target.value
                                                                        }
                                                                    }
                                                                })
                                                            }}
                                                            type="number"
                                                            placeholder="value"
                                                        />{parameter.unit}</td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </Table>
                                        <Button onClick={this.submit} bsStyle="info" pullRight fill>
                                            Add Report
                                        </Button>
                                        <div className="clearfix"/>
                                    </Fragment>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default withRouter(AddReport);
