export const TITLE = 'Farmako Reception';
export const TITLE_SEPERATOR = ' | ';
export const FIREBASE_CONFIG = {
    apiKey: "AIzaSyAbWnSONgXVxwYiK9-bX9znpgsUj_ZAOzQ",
    authDomain: "farmako-hospital.firebaseapp.com",
    databaseURL: "https://farmako-hospital.firebaseio.com",
    projectId: "farmako-hospital",
    storageBucket: "farmako-hospital.appspot.com",
    messagingSenderId: "105624341740",
    appId: "1:105624341740:web:aea026a7dfc383dbd129c9",
    measurementId: "G-ZVQ8TSCZX0"
};
