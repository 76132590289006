import React, {Component, Fragment} from "react";
import {Col, ControlLabel, Grid, Row} from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import Paper from "@material-ui/core/Paper";
import firebase from "firebase";
import Input from "@material-ui/core/Input";
import {withRouter} from 'react-router-dom'
import LinearProgress from "@material-ui/core/LinearProgress";
import Modal from "@material-ui/core/Modal";
import Dropzone from 'react-dropzone'

class Upload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pid: '',
            modal: false,
            prepopulateData: [],
            hid: localStorage.getItem('hospitalId'),
            name: '',
            uploadedDcm: null,
        };

        this.prepopulate = function (phone) {
            this.setState({modal: true});
            this.dbRef.collection('patients').where('phone', '==', phone).get().then((patients) => {
                if (patients.empty) {
                    this.setState({modal: false});
                    return;
                }
                let patientList = [];
                patients.forEach(patient => {
                    patientList.push({...patient.data(), id: patient.id});
                });
                this.setState({prepopulateData: patientList})
            }).catch((e) => {
                alert('Error Fetching Patient List');
            });
        }.bind(this);

        this.submit = function () {
            if(!this.state.uploadedDcm) {
                alert(`Upload a File First`);
                return;
            }
            if (this.state.pid === '') {
                this.dbRef.collection('patients').add({
                    registrationDate: new Date(),
                    phone: this.state.phone,
                }).then((ref) => {
                    this.uploadToPatient(ref.id);
                }).catch((e) => {
                    alert(`Error while registering ${e.stack}`);
                })
            } else {
                this.uploadToPatient(this.state.pid);
            }
        }.bind(this);

        this.uploadToPatient = function (pid) {
            this.dbRef.collection('radio_reports').add({
                createDate: new Date(),
                hid: this.state.hid,
                pid: pid,
                status: 'Pending',
                updatedDate: new Date()
            }).then(ref=>{
                this.storageRef.child(`dcm_files/${pid}/${ref.id}`).put(this.state.uploadedDcm).then(()=>{
                    alert('Uploaded DCM');
                    this.props.history.push(`/path/patients/view/${pid}`)
                })
            })
        }.bind(this);

        this.addTest = function (id) {
            let temp = this.state.selectedTests;
            if (temp.indexOf(id) === -1) temp.push(id);
            this.setState({selectedTests: temp});
        }.bind(this);

        this.removeTest = function (id) {
            let temp = this.state.selectedTests;
            if (temp.indexOf(id) !== -1) temp.splice(temp.indexOf(id), 1);
            this.setState({selectedTests: temp});
        }.bind(this);

        this.dbRef = firebase.firestore();
        this.storageRef = firebase.storage().ref();
        this.hospitalRef = this.dbRef.collection('hospitals').doc(this.state.hid);

        this.hospitalRef.collection('user_details').where('type', '==', 'doc').get().then((doctors) => {
            if (doctors.empty) {
                console.log('No Doctors Found');
                return;
            }
            let docList = [];
            doctors.forEach(doc => {
                docList.push({value: doc.data().name, label: doc.data().name});
            });
            this.setState({doctors: docList});
        }).catch((e) => {
            alert('Error Fetching Doctor List');
        });
        this.hospitalRef.collection('tests').get().then((tests) => {
            if (tests.empty) {
                console.log('No Tests Found');
                return;
            }
            let testList = {};
            tests.forEach(test => {
                testList[test.id] = test.data().name;
            });
            this.setState({tests: testList});
        }).catch((e) => {
            alert(`Error Fetching Tests List ${e.stack}`);
        });
    }

    render() {
        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Paper elevation={8} style={{padding: 20}}>
                                <Row>
                                    <div className="col-md-6" style={{marginBottom: 10}}>
                                        <ControlLabel>Phone Number</ControlLabel><br/>
                                        <Input
                                            style={{fontSize: 15}}
                                            value={this.state.phone}
                                            onChange={(e) => {
                                                if (e.target.value.length > 10) {
                                                    return;
                                                }
                                                this.setState({phone: e.target.value});
                                                if (e.target.value.length === 10) {
                                                    this.prepopulate(e.target.value);
                                                }
                                            }}
                                            fullWidth
                                            type="number"
                                            placeholder="9999999999"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <ControlLabel>Name</ControlLabel><br/>
                                        <Input
                                            value={this.state.name}
                                            disabled={this.state.pid !== ''}
                                            onChange={(e) => {
                                                this.setState({name: e.target.value})
                                            }}
                                            fullWidth
                                            type="text"
                                            placeholder="Name"
                                        />
                                    </div>
                                </Row>
                                <Dropzone onDrop={acceptedFiles => this.setState({uploadedDcm: acceptedFiles[0]})}>
                                    {({getRootProps, getInputProps}) => (
                                        <section>
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <p>Drag 'n' drop DICOM files here, or click to select files</p>
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                                <Button
                                    onClick={this.submit}
                                    bsStyle="info"
                                    style={{marginLeft: 18}}
                                    pullRight
                                    fill
                                >
                                    Upload
                                </Button>
                                <Button
                                    onClick={this.submit}
                                    bsStyle="info"
                                    pullRight
                                    fill
                                >
                                    Upload and Proceed
                                </Button>
                                <div className="clearfix"/>
                            </Paper>
                        </Col>
                    </Row>
                </Grid>
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.modal}
                    onClose={() => {
                    }}
                >
                    {
                        this.state.prepopulateData.length === 0 ?
                            <LinearProgress/>
                            :
                            <div style={{
                                backgroundColor: 'white',
                                width: '40%',
                                margin: 'auto',
                                textAlign: 'center',
                                padding: 5,
                                marginTop: 100,
                                border: "solid 20"
                            }}>
                                <p id="simple-modal-title"
                                   style={{marginBottom: 15, fontSize: 25, marginTop: 10}}>Select the patient below</p>
                                <p id="simple-modal-description">
                                    {this.state.prepopulateData.map(patient => {
                                        return <Fragment>
                                            <Button
                                                onClick={() => {
                                                    this.setState({
                                                        modal: false,
                                                        pid: patient.id,
                                                        phone: patient.phone,
                                                        name: patient.name,
                                                        age: patient.age,
                                                        gender: patient.gender,
                                                        email: patient.email,
                                                        address: patient.address
                                                    })
                                                }}
                                                variant="outlined"
                                            >
                                                {patient.name} ({patient.age} yr(s)/{patient.gender})
                                            </Button>
                                            <br/>
                                        </Fragment>
                                    })}
                                    or<br/>
                                    <Fragment>
                                        <Button style={{marginBottom: 10}} onClick={() => {
                                            this.setState({modal: false, pid: '', prepopulateData: []})
                                        }}>
                                            Add New
                                        </Button>
                                    </Fragment>
                                </p>
                            </div>
                    }
                </Modal>
            </div>
        );
    }
}

export default withRouter(Upload);
