import React, {Component} from "react";
import {NavLink} from "react-router-dom";

import AdminNavbarLinks from "../Navbars/AdminNavbarLinks.jsx";

import logo from "assets/img/logo.png";
import firebase from "firebase";

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth
        };
    }

    activeRoute(routeName) {
        return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
    }

    updateDimensions() {
        this.setState({width: window.innerWidth});
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    render() {
        const sidebarBackground = {
            backgroundImage: "url(" + this.props.image + ")"
        };
        return (
            <div
                id="sidebar"
                className="sidebar"
                data-color={this.props.color}
                data-image={this.props.image}
            >
                {this.props.hasImage ? (
                    <div className="sidebar-background" style={sidebarBackground}/>
                ) : (
                    null
                )}
         <div className="logo">
                    <a
                        href="https://farmako.in/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="simple-text logo-mini"
                    >
                        <div className="logo-img"
                             style={{paddingTop:9, marginLeft:75}}                                                >
                            <img src={logo} alt="logo_image"/>
                        </div>
                    </a>

                </div>


                <div className="sidebar-wrapper">
                    <ul className="nav" style={{marginTop: 27}}>
                        {this.state.width <= 991 ? <AdminNavbarLinks/> : null}
                        {this.props.routes.map((prop, key) => {
                            if (!prop.name) return null;
                            if (prop.logout) {
                                return (
                                    <li
                                        onClick={() => {
                                            firebase.auth().signOut()
                                        }}
                                        className="active active-pro"
                                        key={key}
                                    >
                                        <NavLink
                                            to='#'
                                            className="nav-link"
                                            activeClassName="active"
                                        >
                                            <i className={prop.icon}/>
                                            <p>{prop.name}</p>
                                        </NavLink>
                                    </li>
                                )
                            }
                            return (
                                <li
                                    className={
                                        this.activeRoute(prop.layout + prop.path)
                                    }
                                    key={key}
                                >
                                    <NavLink
                                        to={prop.layout + prop.path}
                                        className="nav-link"
                                        activeClassName="active"
                                    >
                                        <i className={prop.icon}/>
                                        <p>{prop.name}</p>
                                    </NavLink>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        );
    }
}

export default Sidebar;
