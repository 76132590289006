import UserProfile from "views/UserProfile.jsx";
import PatientList from "./views/PatientList.jsx";
import StaffDetails from "./views/StaffDetails";
import Inventory from "./views/Inventory";
import Billing from "./views/Billing";
import Services from "./views/Services";
import AddBill from "./views/AddBill";
import PatientDetails from "./views/PatientDetails";
import ViewBills from "./views/ViewBills";
import BillDetails from "./views/BillDetails";
import EditProfile from "./views/EditProfile";
import EditInventory from "./views/EditInventory";
import EditService from "./views/EditService";

const dashboardRoutes = [
    {
        path: "/register/edit/:patientId",
        component: EditProfile,
        layout: "/admin",
        title: "Edit Details"
    },
    {
        path: "/register",
        name: "Patient Registration",
        icon: "pe-7s-user",
        component: UserProfile,
        layout: "/admin",
        title: "Patient Registration"
    },
    {
        path: "/patients/view/:patientId",
        component: PatientDetails,
        layout: "/admin",
        title: "Patients Detail"
    },
    {
        path: "/patients",
        name: "Patients List",
        icon: "pe-7s-note2",
        component: PatientList,
        layout: "/admin",
        title: "Patients List"
    },
    {
        path: "/billing/view/:patientId",
        component: ViewBills,
        layout: "/admin",
        title: "View Bills"
    },
    {
        path: "/billing/add/:appointmentId",
        component: AddBill,
        layout: "/admin",
        title: "Add Bill"
    },
    {
        path: "/billing/addToPatient/:patientId",
        component: AddBill,
        layout: "/admin",
        title: "Add Bill"
    },
    {
        path: "/billing/bill/:billId",
        component: BillDetails,
        layout: "/admin",
        title: "Bill Details"
    },
    {
        path: "/billing",
        name: "Billing and Accounting",
        icon: "pe-7s-credit",
        component: Billing,
        layout: "/admin",
        title: "Billing and Accounting"
    },
    {
        path: "/inventory/edit/:id",
        component: EditInventory,
        layout: "/admin",
        title: "Edit Inventory"
    },
    {
        path: "/inventory",
        name: "Inventory",
        icon: "pe-7s-server",
        component: Inventory,
        layout: "/admin",
        title: "Inventory"
    },
    {
        path: "/services/edit/:id",
        component: EditService,
        layout: "/admin",
        title: "Edit Services"
    },
    {
        path: "/services",
        name: "Services",
        icon: "pe-7s-portfolio",
        component: Services,
        layout: "/admin",
        title: "Services"
    },
    {
        path: "/staffs",
        name: "Staff Details",
        icon: "pe-7s-id",
        component: StaffDetails,
        layout: "/admin",
        title: "Staff Details"
    },
    {
        logout: true,
        name: "Logout",
        icon: "pe-7s-power",
        layout: "/admin"
    }
];

export default dashboardRoutes;
