import React, {Component} from "react";
import {Table} from "react-bootstrap";

import {Card} from "components/Card/Card.jsx";
import firebase from "firebase";
import {withRouter} from "react-router";

class ListTests extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tests: []
        };

        this.dbRef = firebase.firestore();
        this.hospitalRef = this.dbRef.collection('hospitals').doc(localStorage.getItem('hospitalId'));

        this.hospitalRef.collection('tests').get().then((snapshot)=>{
            if (snapshot.empty) {
                alert('No Test Data Available.');
                return;
            }

            let testList = [];
            snapshot.forEach(test => {
                testList.push({...test.data(), id: test.id});
            });
            this.setState({tests: testList});
        });
    }

    render() {
        return <Card
            title="Tests List"
            content={
                <Table striped hover>
                    <thead>
                    <tr>
                        <th key='0'>S.No.</th>
                        <th key='1'>Name</th>
                        <th key='2'>Price</th>
                        <th key='3'>Sample Type</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.state.tests.map((test, sno)=>{
                            return (
                                <tr onClick={()=>{
                                    this.props.history.push(`/path/test/${test.id}`);
                                }}
                                    key={sno}
                                >
                                    <td key={0}>{sno+1}</td>
                                    <td key={1}>{test.name}</td>
                                    <td key={2}>{test.price}</td>
                                    <td key={3}>{test.sampleType}</td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </Table>
            }
        />;
    }
}

export default withRouter(ListTests);
