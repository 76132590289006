import React, {Component} from "react";
import {Col, ControlLabel, FormGroup, Grid, Row} from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import MUIButton from '@material-ui/core/Button';
import "react-datepicker/dist/react-datepicker.css";
import Paper from "@material-ui/core/Paper";
import firebase from "firebase";
import Input from "@material-ui/core/Input";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import {withRouter} from 'react-router-dom'

class EditProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patientId: props.match.params.patientId,
            phone: '',
            name: '',
            age: '',
            gender: 'male',
            email: '',
            address: '',
        };

        this.submit = function () {
            this.dbRef.collection('patients').doc(this.state.patientId).set({
                updatedDate: new Date(),
                phone: this.state.phone,
                name: this.state.name,
                age: this.state.age,
                gender: this.state.gender,
                email: this.state.email,
                address: this.state.address,
            }, {merge: true}).then(() => {
                alert(`Updated Successfully with ID: ${this.state.patientId}`);
                this.props.history.push(`/admin/patients/view/${this.state.patientId}`)
            }).catch((e) => {
                alert(`Error while updating ${e.stack}`);
            })
        }.bind(this);

        this.dbRef = firebase.firestore();
        this.hospitalRef = this.dbRef.collection('hospitals').doc(localStorage.getItem('hospitalId'));

        this.dbRef.collection('patients').doc(this.state.patientId).get().then((patient) => {
            this.setState({
                registrationDate: new Date(),
                phone: patient.data().phone,
                name: patient.data().name,
                age: patient.data().age,
                gender: patient.data().gender,
                email: patient.data().email,
                address: patient.data().address,
            });
        }).catch((e) => {
            alert('Error Fetching Patient Details: '+e.stack);
        });
    }

    render() {
        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Paper style={{padding: 10}}>
                                <Row>
                                    <div className="col-md-6">
                                        <ControlLabel>Name</ControlLabel><br/>
                                        <Input
                                            value={this.state.name}
                                            onChange={(e) => {
                                                this.setState({name: e.target.value})
                                            }}
                                            fullWidth
                                            type="text"
                                            placeholder="Name"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <ControlLabel>Phone Number</ControlLabel><br/>
                                        <Input
                                            value={this.state.phone}
                                            onChange={(e) => {
                                                this.setState({phone: e.target.value})
                                            }}
                                            fullWidth
                                            type="number"
                                            placeholder="9999999999"
                                        />
                                    </div>
                                </Row>
                                <Row>
                                    <div className="col-md-6">
                                        Gender<br/>
                                        <MUIButton
                                            variant="contained"
                                            color={this.state.gender === 'male' ? 'primary' : ''}
                                            onClick={() => {
                                                this.setState({gender: 'male'})
                                            }}
                                        >
                                            Male
                                        </MUIButton>&nbsp;&nbsp;&nbsp;
                                        <MUIButton
                                            variant="contained"
                                            color={this.state.gender === 'female' ? 'primary' : ''}
                                            onClick={() => {
                                                this.setState({gender: 'female'})
                                            }}
                                        >
                                            Female
                                        </MUIButton>&nbsp;&nbsp;&nbsp;
                                        <MUIButton
                                            variant="contained"
                                            color={this.state.gender === 'others' ? 'primary' : ''}
                                            onClick={() => {
                                                this.setState({gender: 'others'})
                                            }}
                                        >
                                            Others
                                        </MUIButton>
                                    </div>
                                    <div className="col-md-6">
                                        <ControlLabel>Age</ControlLabel><br/>
                                        <Input
                                            value={this.state.age}
                                            onChange={(e) => {
                                                this.setState({age: e.target.value})
                                            }}
                                            fullWidth
                                            type="number"
                                            placeholder="Age"
                                        />
                                    </div>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup controlId="formControlsTextarea">
                                            <ControlLabel>Email</ControlLabel>
                                            <Input
                                                value={this.state.email}
                                                onChange={(e) => {
                                                    this.setState({email: e.target.value})
                                                }}
                                                fullWidth
                                                type="text"
                                                placeholder="Email"
                                            />
                                        </FormGroup>
                                        <FormGroup controlId="formControlsTextarea">
                                            <ControlLabel>Address</ControlLabel><br/>
                                            <TextareaAutosize
                                                value={this.state.address}
                                                onChange={(e) => {
                                                    this.setState({address: e.target.value})
                                                }}
                                                style={{height: 150, width: '100%'}}
                                                type="textarea"
                                                placeholder="Address"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Button
                                    onClick={this.submit}
                                    bsStyle="info"
                                    style={{marginLeft: 18}}
                                    pullRight
                                    fill
                                >
                                    Update Patient Details
                                </Button>
                                <div className="clearfix"/>
                            </Paper>
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default withRouter(EditProfile);
