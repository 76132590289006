import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import { staffThArray } from "variables/Variables.jsx";
import firebase from "firebase";

class StaffDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            staffs: [],
        };

        this.hospitalRef = firebase.firestore().collection('hospitals').doc(localStorage.getItem('hospitalId'));

        this.hospitalRef.collection('user_details').get().then((snapshot)=>{
            if (snapshot.empty) {
                alert('No Staffs Available.');
                return;
            }

            let staffs = [];
            snapshot.forEach(staff => {
                staffs.push({
                    id: staff.id,
                    name: staff.data().name,
                    designation: staff.data().type,
                    department: staff.data().department,
                    availability: staff.data().availability
                });
            });
            this.setState({staffs})
        });
    }
  render() {
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Staff Details"
                category="List of all staffs"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <Table striped hover>
                    <thead>
                      <tr>
                        {staffThArray.map((prop, key) => {
                          return <th key={key}>{prop}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.staffs.map((prop, sno) => {
                        return (
                          <tr key={sno}>
                            <td key='0'>{prop.id}</td>
                            <td key='1'>{prop.name}</td>
                            <td key='2'>{prop.designation}</td>
                            <td key='3'>{prop.department}</td>
                            <td key='4'>{prop.availability}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default StaffDetails;
