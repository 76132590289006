import React, {Component} from "react";
import {Col, Grid, Row, Table} from "react-bootstrap";

import {Card} from "components/Card/Card.jsx";
import firebase from "firebase";
import {withRouter} from "react-router";

class Billing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bills: []
        };

        this.hospitalRef = firebase.firestore().collection('hospitals').doc(localStorage.getItem('hospitalId'));

        this.hospitalRef.collection('invoices').get().then((invoices) => {
            if (invoices.empty) {
                console.log('No matching invoices.');
                return;
            }

            let bills = [];

            invoices.forEach(invoice => {
                bills.push({...invoice.data(), id: invoice.id});
            });

            this.setState({bills})
        }).catch((e) => {
            alert(`Error Getting Patient Detail ${e.stack}`);
        });
    }
    render() {
        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title={`Billing Details`}
                                content={
                                    <div>
                                        <Table striped hover>
                                            <thead>
                                            <tr>
                                                <th key='0'>S.No</th>
                                                <th key='1'>Invoice ID</th>
                                                <th key='2'>Patient ID</th>
                                                <th key='3'>Date/Time</th>
                                                <th key='4'>Generated By</th>
                                                <th key='5'>Amount</th>
                                                <th key='6'>Paid</th>
                                                <th key='7'>Unpaid</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.bills.map((invoice, sno) => {
                                                return (
                                                    <tr onClick={()=> this.props.history.push(`/path/billing/bill/${invoice.id}`)} key={sno}>
                                                        <td key='0'>{sno+1}</td>
                                                        <td key='1'>{invoice.id}</td>
                                                        <td key='2'>{invoice.patientId}</td>
                                                        <td key='3'>{new Date(invoice.date? invoice.date.second: null).toDateString()}</td>
                                                        <td key='4'>{invoice.generatedBy}</td>
                                                        <td key='5'>{invoice.amount}</td>
                                                        <td key='6'>{invoice.amount}</td>
                                                        <td key='7'>0</td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </Table>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default withRouter(Billing);
