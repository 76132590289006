import React, {Component} from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import "assets/css/Layouts/Login.css";
import logo from "assets/img/farmako_logo.png";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {TITLE, TITLE_SEPERATOR} from "../config";
import firebase from "firebase";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phone: '',
            password: '',
        };
    }

    componentDidMount() {
        document.title = TITLE+TITLE_SEPERATOR+"Login";
    }

    componentWillUnmount() {
        document.title = TITLE;
    }

    render() {
        return (
            <Grid container>
                <Grid item md={4}/>
                <Grid item md={4} style={{paddingTop:50,paddingBottom:50}}>
                    <Paper elevation={10} className="text-center" style={{backgroundColor:'#eeeeee', padding:70}}>
                        <img alt="" style={{borderRadius:6}} src={logo} height={75}/>
                        <br/>
                        <TextField
                            style={{width:'80%'}}
                            value={this.state.phone}
                            onChange={(e) => {
                                this.setState({
                                    phone: e.target.value
                                });
                            }}
                            type="number"
                            label="Phone"
                            margin="normal"
                            variant="outlined"
                        />
                        <br/>
                        <TextField
                            style={{width:'80%'}}
                            value={this.state.password}
                            onChange={(e) => {
                                this.setState({
                                    password: e.target.value
                                });
                            }}
                            type="password"
                            label="Password"
                            margin="normal"
                            variant="outlined"
                        />
                        <div className="text-center">
                            <Button
                                style={{marginTop:15, padding:10}}
                                onClick={() => {
                                this.submit()
                            }} variant="outlined">
                                Login
                            </Button>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        );
    }

    submit() {
        const phone = this.state.phone;
        const password = this.state.password;
        if(phone==='' || password==='') {
            alert('Fill the details completely');
        } else {
            firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(function() {
                return firebase.auth().signInWithEmailAndPassword(`91${phone}@users.farmako.in`, password);
            }).then(()=>{
                console.log("Logged In");
            }).catch((e)=>{
                alert(`Error Logging In`);
                console.log(e);
            });
        }
    }
}

export default Login;
