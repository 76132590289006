import React, {Component} from "react";
import {ControlLabel, Row} from "react-bootstrap";

import {Card} from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Input from "@material-ui/core/Input";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import firebase from "firebase";

class EditInventory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,
            name: '',
            description: '',
            price: 0,
            quantity: 0,
        };
        this.hospitalRef = firebase.firestore().collection('hospitals').doc(localStorage.getItem('hospitalId'));
        this.hospitalRef.collection('inventory').doc(this.state.id).get().then((inventory)=>{
            this.setState({...inventory.data()});
        });
        this.submit = this.submit.bind(this);
    }

    submit() {
        let {name, description, price, quantity} = this.state;
        if (!name || name === '') {
            alert('Fill Name');
            return;
        }
        if (!price || price === 0) {
            alert('Fill Price');
            return;
        }
        if (!quantity || quantity === 0) {
            alert('Fill Quantity');
            return;
        }
        if (!description || description === '') {
            alert('Fill Description');
            return;
        }
        this.hospitalRef.collection('inventory').doc(this.state.id).set({
            name, description, price, quantity
        }, {merge: true}).then(() => {
            alert('Successfully Updated');
            this.props.history.push(`/admin/inventory`);
        }).catch((e) => {
            alert(`Error while updating Inventory: ${e.stack}`);
        })

    }

    render() {
        return (
            <div className="content">
                <Card
                    title="Add Inventory"
                    content={
                        <div>
                            <Row>
                                <div className="col-md-12">
                                    <ControlLabel>Name</ControlLabel><br/>
                                    <Input
                                        value={this.state.name}
                                        onChange={(e) => {
                                            this.setState({name: e.target.value})
                                        }}
                                        fullWidth
                                        type="text"
                                        placeholder="Inventory Name"
                                    />
                                </div>
                            </Row>
                            <Row>
                                <div className="col-md-6">
                                    <ControlLabel>Price</ControlLabel><br/>
                                    <Input
                                        value={this.state.price}
                                        onChange={(e) => {
                                            this.setState({price: e.target.value})
                                        }}
                                        fullWidth
                                        type="number"
                                        placeholder="Inventory Price"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <ControlLabel>Quantity</ControlLabel><br/>
                                    <Input
                                        value={this.state.quantity}
                                        onChange={(e) => {
                                            this.setState({quantity: e.target.value})
                                        }}
                                        fullWidth
                                        type="number"
                                        placeholder="Inventory Quantity"
                                    />
                                </div>
                            </Row>
                            <Row>
                                <div className="col-md-12">
                                    <ControlLabel>Details</ControlLabel><br/>
                                    <TextareaAutosize
                                        value={this.state.description}
                                        onChange={(e) => {
                                            this.setState({description: e.target.value})
                                        }}
                                        style={{height: 150, width: '100%'}}
                                        type="textarea"
                                        placeholder="Inventory Description"
                                    />
                                </div>
                            </Row>
                            <Button
                                bsStyle="info"
                                pullRight
                                fill
                                onClick={this.submit}
                            >
                                Update Inventory
                            </Button>
                            <div className="clearfix"/>
                        </div>
                    }
                />
            </div>
        );
    }
}

export default EditInventory;
