import React, {Component, Fragment} from "react";
import {Col, ControlLabel, FormGroup, Grid, Row} from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import MUIButton from '@material-ui/core/Button';
import Select from 'react-select'
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import firebase from "firebase";
import Input from "@material-ui/core/Input";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import {withRouter} from 'react-router-dom'
import Modal from "@material-ui/core/Modal";
import LinearProgress from "@material-ui/core/LinearProgress";

class UserProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pid: '',
            modal: false,
            prepopulateData: [],
            doctors: [],
            products: {},
            appointmentDateDelta: 0,
            selectedSlot: 0,
            selectedDoctor: '',
            phone: '',
            name: '',
            age: '',
            gender: 'male',
            email: '',
            address: '',
            selectedServices: {},
        };

        this.submit = function () {
            let appDate = new Date();
            appDate.setDate(appDate.getDate() + this.state.appointmentDateDelta);
            if (this.state.pid === '') {
                this.dbRef.collection('patients').add({
                    registrationDate: new Date(),
                    phone: this.state.phone,
                    name: this.state.name,
                    age: this.state.age,
                    gender: this.state.gender,
                    email: this.state.email,
                    address: this.state.address,
                }).then((ref) => {
                    this.hospitalRef.collection('appointments').add({
                        pid: ref.id,
                        creationDate: new Date(),
                        appointmentDate: appDate,
                        selectedDoctor: this.state.selectedDoctor,
                        selectedSlot: this.state.selectedSlot,
                        selectedServices: this.state.selectedServices,
                        status: "Pending",
                    }).then((appRef) => {
                        alert(`Added Successfully with ID: ${ref.id} and appointment booked with ID: ${appRef.id}`);
                        this.props.history.push(`/admin/billing/add/${appRef.id}`)
                    })
                }).catch((e) => {
                    alert(`Error while creating Appointment ${e.stack}`);
                })
            } else {
                this.hospitalRef.collection('appointments').add({
                    pid: this.state.pid,
                    creationDate: new Date(),
                    appointmentDate: appDate,
                    selectedDoctor: this.state.selectedDoctor,
                    selectedSlot: this.state.selectedSlot,
                    selectedServices: this.state.selectedServices,
                    status: "Pending",
                }).then((appRef) => {
                    alert(`Appointment booked with ID: ${appRef.id}`);
                    this.props.history.push(`/admin/billing/add/${appRef.id}`)
                }).catch((e) => {
                    alert(`Error while creating Appointment ${e.stack}`);
                })
            }
        }.bind(this);

        this.prepopulate = function (phone) {
            this.setState({modal: true});
            this.dbRef.collection('patients').where('phone', '==', phone).get().then((patients) => {
                if (patients.empty) {
                    this.setState({modal: false});
                    return;
                }
                let patientList = [];
                patients.forEach(patient => {
                    patientList.push({...patient.data(), id: patient.id});
                });
                this.setState({prepopulateData: patientList})
            }).catch((e) => {
                alert('Error Fetching Patient List');
            });
        }.bind(this);

        this.addProduct = function (id) {
            if (this.state.products[id]) {
                let temp = this.state.selectedServices;
                temp[id] = {type: this.state.products[id].type};
                this.setState({selectedServices: temp});
            }
        }.bind(this);

        this.removeProduct = function (id) {
            let temp = this.state.selectedServices;
            delete temp[id];
            this.setState({selectedServices: temp});
        }.bind(this);

        this.dbRef = firebase.firestore();
        this.hospitalRef = this.dbRef.collection('hospitals').doc(localStorage.getItem('hospitalId'));

        this.hospitalRef.collection('user_details').where('type', '==', 'doc').get().then((doctors) => {
            if (doctors.empty) {
                console.log('No Doctors Found');
                return;
            }
            let docList = [];
            doctors.forEach(doc => {
                docList.push({value: doc.id, label: doc.data().name});
            });
            this.setState({doctors: docList});
        }).catch((e) => {
            alert('Error Fetching Doctor List');
        });
        this.hospitalRef.collection('services').get().then((services) => {
            if (services.empty) {
                console.log('No Services Found');
                return;
            }
            let srvList = {};
            services.forEach(service => {
                srvList[service.id] = {name: service.data().name, type: 'service'};
            });
            this.setState({products: srvList});
        }).catch((e) => {
            alert(`Error Fetching Services List ${e.stack}`);
        }).finally(() => {
            this.hospitalRef.collection('inventory').get().then((inventory) => {
                if (inventory.empty) {
                    console.log('No inventory Found');
                    return;
                }
                let invList = this.state.products;
                inventory.forEach(inventory => {
                    invList[inventory.id] = {name: inventory.data().name, type: 'inventory'};
                });
                this.setState({products: invList});
            })
        });
    }

    render() {
        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={14} style={{marginTop: 10, padding: 15}}>
                            <Paper elevation={8} style={{padding: 30}}>
                                <Row>
                                    <div className="col-md-6" style={{marginBottom: 10}}>
                                        <div
                                            style={{marginBottom: 7}}
                                        >
                                            <b>
                                                Doctor
                                            </b>
                                        </div>
                                        <Select
                                            options={this.state.doctors}
                                            onChange={(e) => {
                                                this.setState({selectedDoctor: e.value})
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-3"
                                         style={{marginBottom: 20}}
                                    >
                                        <div
                                            style={{marginBottom: 7}}
                                        >
                                            <b>
                                                Appointment
                                            </b>
                                        </div>

                                        <MUIButton
                                            variant="contained"
                                            color={this.state.appointmentDateDelta === 0 ? 'primary' : ''}
                                            onClick={() => {
                                                this.setState({appointmentDateDelta: 0})
                                            }}
                                        >
                                            Today
                                        </MUIButton>&nbsp;&nbsp;&nbsp;
                                        <MUIButton
                                            variant="contained"
                                            color={this.state.appointmentDateDelta === 1 ? 'primary' : ''}
                                            onClick={() => {
                                                this.setState({appointmentDateDelta: 1})
                                            }}
                                        >
                                            Tomorrow
                                        </MUIButton>
                                    </div>
                                    <div className="col-md-3">
                                        <div
                                            style={{marginBottom: 7}}
                                        >
                                            <b>
                                                Slot
                                            </b>

                                        </div>
                                        <MUIButton
                                            variant="contained"
                                            color={this.state.selectedSlot === 0 ? 'primary' : ''}
                                            onClick={() => {
                                                this.setState({selectedSlot: 0})
                                            }}
                                        >
                                            Morning
                                        </MUIButton>&nbsp;&nbsp;&nbsp;
                                        <MUIButton
                                            variant="contained"
                                            color={this.state.selectedSlot === 1 ? 'primary' : ''}
                                            onClick={() => {
                                                this.setState({selectedSlot: 1})
                                            }}
                                        >
                                            Evening
                                        </MUIButton>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="col-md-6"
                                         style={{marginBottom: 20}}
                                    >
                                        <ControlLabel>Phone Number</ControlLabel><br/>
                                        <Input
                                            style={{fontSize: 15}}
                                            value={this.state.phone}
                                            onChange={(e) => {
                                                if (e.target.value.length > 10) {
                                                    return;
                                                }
                                                this.setState({phone: e.target.value});
                                                if (e.target.value.length === 10) {
                                                    this.prepopulate(e.target.value);
                                                }
                                            }}
                                            fullWidth
                                            type="number"
                                            placeholder="9999999999"
                                        />
                                    </div>
                                    <div className="col-md-6"
                                    >
                                        <ControlLabel>Name</ControlLabel><br/>
                                        <Input
                                            style={{fontSize: 15}}
                                            disabled={this.state.pid !== ''}
                                            value={this.state.name}
                                            onChange={(e) => {
                                                this.setState({name: e.target.value})
                                            }}
                                            fullWidth
                                            type="text"
                                            placeholder="Name"
                                        />
                                    </div>
                                </Row>
                                <Row>
                                    <div className="col-md-6">
                                        <ControlLabel>Age</ControlLabel><br/>
                                        <Input
                                            style={{fontSize: 15}}
                                            disabled={this.state.pid !== ''}
                                            value={this.state.age}
                                            onChange={(e) => {
                                                this.setState({age: e.target.value})
                                            }}
                                            fullWidth
                                            type="number"
                                            placeholder="Age"
                                        />
                                    </div>
                                    <div className="col-md-6"
                                         style={{marginTop: 7}}

                                    >
                                        <div
                                            style={{marginBottom: 5}}
                                        >
                                            <b>
                                                Gender
                                            </b>
                                        </div>
                                        <MUIButton
                                            variant="contained"
                                            color={this.state.gender === 'male' ? 'primary' : ''}
                                            onClick={() => {
                                                if (this.state.pid !== '') return;
                                                this.setState({gender: 'male'})
                                            }}
                                        >
                                            Male
                                        </MUIButton>&nbsp;&nbsp;&nbsp;
                                        <MUIButton
                                            variant="contained"
                                            color={this.state.gender === 'female' ? 'primary' : ''}
                                            onClick={() => {
                                                if (this.state.pid !== '') return;
                                                this.setState({gender: 'female'})
                                            }}
                                        >
                                            Female
                                        </MUIButton>&nbsp;&nbsp;&nbsp;
                                        <MUIButton
                                            variant="contained"
                                            color={this.state.gender === 'others' ? 'primary' : ''}
                                            onClick={() => {
                                                if (this.state.pid !== '') return;
                                                this.setState({gender: 'others'})
                                            }}
                                        >
                                            Others
                                        </MUIButton>

                                    </div>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Paper style={{padding: 10, height: 250}}>
                                            <div
                                                style={{marginBottom: 8}}
                                            >
                                                <b>
                                                    Services
                                                </b>
                                            </div>
                                            <Paper style={{padding: 10, height: 40}}>
                                                {
                                                    Object.keys(this.state.selectedServices).map((id) => {
                                                        return <Fragment  style={{marginBottom:30}}>
                                                            <Chip
                                                                style={{padding:5}}
                                                                color="primary"
                                                                label={this.state.products[id].name}
                                                                onDelete={() => {
                                                                    this.removeProduct(id)
                                                                }}
                                                            />
                                                            &nbsp;&nbsp;&nbsp;
                                                        </Fragment>;
                                                    })
                                                }
                                            </Paper>
                                            <br/>
                                            <Paper style={{padding: 10}}>
                                                <MUIButton
                                                    onClick={() => {
                                                        this.addProduct('gPW9d4iMuvg9z149PvoW')
                                                    }}
                                                    variant="contained">OPD</MUIButton>&nbsp;&nbsp;&nbsp;
                                                <MUIButton
                                                    onClick={() => {
                                                        this.addProduct('gPW9d4iMuvg9z149PvoW')
                                                    }}
                                                    variant="contained">Appointment</MUIButton>&nbsp;&nbsp;&nbsp;
                                                <MUIButton
                                                    onClick={() => {
                                                        this.addProduct('4yTLeyKjESrS3nHP3c1j')
                                                    }}
                                                    variant="contained">Follow-up</MUIButton>&nbsp;&nbsp;&nbsp;
                                                
                                                <br/>
                                                <br/>
                                                <Select
                                                    name="services"
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    options={Object.keys(this.state.products).map((id) => {
                                                        return {value: id, label: this.state.products[id].name};
                                                    })}
                                                    onChange={(e) => {
                                                        this.addProduct(e.value)
                                                    }}
                                                />
                                            </Paper>
                                        </Paper>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup

                                            controlId="formControlsTextarea"
                                            style={{marginTop: 20}}
                                        >
                                            <ControlLabel>Email</ControlLabel>
                                            <Input
                                                style={{fontSize: 15}}
                                                disabled={this.state.pid !== ''}
                                                value={this.state.email}
                                                onChange={(e) => {
                                                    this.setState({email: e.target.value})
                                                }}
                                                fullWidth
                                                type="text"
                                                placeholder="Email"
                                            />
                                        </FormGroup>
                                        <FormGroup

                                            controlId="formControlsTextarea"
                                            style={{marginBottom: 8}}
                                        >

                                            <ControlLabel>
                                                <div
                                                    style={{marginBottom: "50"}}
                                                >
                                                    Address
                                                </div>
                                            </ControlLabel>

                                            <TextareaAutosize
                                                disabled={this.state.pid !== ''}
                                                value={this.state.address}
                                                onChange={(e) => {
                                                    this.setState({address: e.target.value})
                                                }}
                                                style={{height: 83, width: '100%'}}
                                                type="textarea"
                                                placeholder=""
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Button
                                    onClick={this.submit}
                                    bsStyle="info"
                                    style={{marginLeft: 18}}
                                    pullRight
                                    fill
                                >
                                    Add Patient
                                </Button>
                                <Button
                                    onClick={this.submit}
                                    bsStyle="info"
                                    pullRight
                                    fill
                                >
                                    Add Patient and Checkout
                                </Button>
                                <div className="clearfix"/>
                            </Paper>
                        </Col>
                    </Row>
                </Grid>
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.modal}
                    onClose={() => {
                    }}
                >
                    {
                        this.state.prepopulateData.length === 0 ?
                            <LinearProgress />
                            :
                            <div style={{backgroundColor: 'white', width:'40%', margin: 'auto', textAlign:'center', padding: 5, marginTop:100, border: "solid 20"}}>
                                <p id="simple-modal-title" style={{marginBottom:15, fontSize: 25, marginTop: 10}}>Select the patient below</p>
                                <p id="simple-modal-description">
                                    {this.state.prepopulateData.map(patient=>{
                                        return <Fragment>
                                            <Button
                                                onClick={()=>{
                                                    this.setState({
                                                        modal:false,
                                                        pid: patient.id,
                                                        phone: patient.phone,
                                                        name: patient.name,
                                                        age: patient.age,
                                                        gender: patient.gender,
                                                        email: patient.email,
                                                        address: patient.address
                                                    })
                                                }}
                                                variant="outlined"
                                            >
                                                {patient.name} ({patient.age} yr(s)/{patient.gender})
                                            </Button>
                                            <br/>
                                        </Fragment>
                                    })}
                                    or<br/>
                                    <Fragment>
                                        <Button style={{marginBottom:10}} onClick={()=>{this.setState({modal:false, pid:'', prepopulateData:[]})}}>
                                            Add New
                                        </Button>
                                    </Fragment>
                                </p>
                            </div>
                    }
                </Modal>
            </div>
        );
    }
}

export default withRouter(UserProfile);
