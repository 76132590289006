import React, {Component} from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper"
import Box from '@material-ui/core/Box';
import AddTest from "./AddTest";
import ListTests from "./ListTests";

class Tests extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabIndex: 0,
        };
    }

    render() {
        return (
            <div className="content" style={{padding:50}}>
            <Box display="flex" justifyContent="center" style={{marginBottom:30, marginTop: -15}}>
            <Paper elevation={4} square>
                <Tabs
                    value={this.state.tabIndex}
                    onChange={() => {
                        this.setState({
                            tabIndex: this.state.tabIndex === 0 ? 1 : 0
                        })
                    }}
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="full width tabs example"

                >

                    <Tab style={{fontSize: 10}} label="Add Test"/>
                    <Tab style={{fontSize: 10}} label="List Tests"/>
                </Tabs>
            </Paper>
            </Box>
                {
                    this.state.tabIndex === 0 ?
                        <AddTest/>
                        :
                        <ListTests/>
                }
            </div>
        );
    }
}

export default Tests;
