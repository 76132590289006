import React, {Component, Fragment} from "react";
import {Col, Grid, Row, Table} from "react-bootstrap";

import {Card} from "components/Card/Card.jsx";
import {billThArray} from "variables/Variables.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import firebase from "firebase";
import ReactToPrint from "react-to-print";
import {PDFExport} from '@progress/kendo-react-pdf';

class Billing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            billId: props.match.params.billId,
            billDetails: {items: {}},
            products: {},
        };

        this.hospitalRef = firebase.firestore().collection('hospitals').doc(localStorage.getItem('hospitalId'));

        this.hospitalRef.collection('invoices').doc(this.state.billId).get().then((billDetails) => {
            this.setState({billDetails: billDetails.data()});

        }).catch((e) => {
            alert(`Error Fetching Bill Details ${e.stack}`);
        });

        this.hospitalRef.collection('tests').get().then((tests) => {
            if (tests.empty) {
                console.log('No Tests Found');
                return;
            }
            let tstList = {};
            tests.forEach(test => {
                tstList[test.id] = {name: test.data().name, rate: test.data().price, type: 'test'};
            });
            this.setState({products: tstList});
        }).catch((e) => {
            alert(`Error Fetching Services List ${e.stack}`);
        });
    }

    render() {
        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title={`Bill Details (${this.state.billId})`}
                                content={
                                    <Fragment>
                                        <Table striped hover>
                                            <thead>
                                            <tr>
                                                {billThArray.map((prop, key) => {
                                                    return <th key={key}>{prop}</th>;
                                                })}
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {Object.keys(this.state.billDetails.items).map((id, sno) => {
                                                return (
                                                    <tr key={id}>
                                                        <td key={0}>{sno + 1}</td>
                                                        <td key={1}>{this.state.products[id] ? this.state.products[id].name : "Loading"}</td>
                                                        <td key={2}>{this.state.billDetails.items[id].rate}</td>
                                                        <td key={3}>{this.state.billDetails.items[id].qty}</td>
                                                        <td key={4}>{this.state.billDetails.items[id].rate * this.state.billDetails.items[id].qty}</td>
                                                    </tr>
                                                );
                                            })}
                                            <tr>
                                                <td key={0}/>
                                                <td key={1}/>
                                                <td key={2}/>
                                                <td key={3}>Total Amount</td>
                                                <td key={4}>{this.state.billDetails.amount} INR</td>

                                            </tr>
                                            </tbody>
                                        </Table>
                                        <Button onClick={() => {
                                            this.bill.save()
                                        }} bsStyle="info" pullRight fill>
                                            Save bill
                                        </Button>
                                        <ReactToPrint
                                            trigger={() => <Button onClick={() => {
                                            }} bsStyle="info" pullRight fill>
                                                Print bill
                                            </Button>}
                                            content={() => this.printableBill}
                                        />
                                        <div style={{position: "absolute", left: "-1000px", top: 0}}>
                                            <PDFExport paperSize={'A4'}
                                                       fileName="Invoice.pdf"
                                                       title=""
                                                       subject=""
                                                       keywords=""
                                                       ref={(r) => this.bill = r}>
                                                <div style={{maxWidth: '900px'}} ref={el => (this.printableBill = el)}>
                                                    Sample Bill Here
                                                </div>
                                            </PDFExport>
                                        </div>
                                        <div className="clearfix"/>
                                    </Fragment>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default Billing;
