import React, {Component} from "react";
import {Col, Grid, Row, Table} from "react-bootstrap";

import {Card} from "components/Card/Card.jsx";
import {billThArray} from "variables/Variables.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import firebase from "firebase";
import Input from "@material-ui/core/Input";
import Select from "react-select";
import {withRouter} from "react-router";

class AddBill extends Component {
    constructor(props) {
        super(props);
        this.state = {
            appointmentId: props.match.params.appointmentId,
            patientId: props.match.params.patientId,
            patientDetails: {},
            appointmentDetails: {},
            products: {},
            invoice: {},
        };

        this.dbRef = firebase.firestore();
        this.hospitalRef = this.dbRef.collection('hospitals').doc(localStorage.getItem('hospitalId'));

        this.updatePatientData = function() {
            this.dbRef.collection('patients').doc(this.state.patientId).get().then((patientDetails) => {
                this.setState({patientDetails: patientDetails.data()});
            })
        }.bind(this);

        if (this.state.appointmentId) {
            this.hospitalRef.collection('appointments').doc(this.state.appointmentId).get().then((appointmentDetails) => {
                let curInvoice = this.state.invoice;
                Object.keys(appointmentDetails.data().selectedServices).forEach((id) => {
                    curInvoice[id] = {
                        qty: 1,
                        type: appointmentDetails.data().selectedServices[id].type
                    }
                });
                this.setState({
                    appointmentDetails: appointmentDetails.data(),
                    patientId: appointmentDetails.data().pid,
                    invoice: curInvoice
                });
                this.updatePatientData();
            }).catch((e) => {
                alert('Error Fetching Details');
            });
        } else {
            this.updatePatientData();
        }
        this.hospitalRef.collection('services').get().then((services) => {
            if (services.empty) {
                console.log('No Doctors Found');
                return;
            }
            let srvList = {};
            services.forEach(service => {
                srvList[service.id] = {name: service.data().name, rate: service.data().price, type: 'service'};
            });
            this.setState({products: srvList});
        }).catch((e) => {
            alert(`Error Fetching Services List ${e.stack}`);
        }).finally(() => {
            this.hospitalRef.collection('inventory').get().then((inventory) => {
                if (inventory.empty) {
                    console.log('No Doctors Found');
                    return;
                }
                let invList = this.state.products;
                inventory.forEach(inventory => {
                    invList[inventory.id] = {
                        name: inventory.data().name,
                        rate: inventory.data().price,
                        type: 'inventory'
                    };
                });
                this.setState({products: invList});
            })
        });

        this.changeQty = function (id, value) {
            if (value < 1) return;
            let curInvoice = this.state.invoice;
            curInvoice[id].qty = value;
            this.setState({invoice: curInvoice})
        }.bind(this);

        this.remove = function (id) {
            let curInvoice = this.state.invoice;
            delete curInvoice[id];
            this.setState({invoice: curInvoice})
        }.bind(this);

        this.addProduct = function (id) {
            let curInvoice = this.state.invoice;
            curInvoice[id] = {qty: 1, type: this.state.products[id].type};
            this.setState({invoice: curInvoice})
        }.bind(this);

        this.generateBill = function () {
            let error = false;
            let amount = 0;
            let curInvoice = this.state.invoice;
            if (!curInvoice) {
                alert(`Add atleast one item to proceed`);
                return;
            }
            Object.keys(curInvoice).forEach((id) => {
                if (!this.state.products[id]) {
                    error = true;
                    return;
                }
                curInvoice[id].rate = this.state.products[id].rate;
                amount += curInvoice[id].rate * curInvoice[id].qty;
            });
            if (error) {
                alert('Invoice not fully loaded');
                return;
            }
            this.hospitalRef.collection('invoices').add({
                invoiceDate: new Date(),
                generatedBy: firebase.auth().currentUser.uid,
                patientId: this.state.patientId,
                items: curInvoice,
                amount
            }).then((invoiceRef) => {
                alert(`Invoice Generated with ID: ${invoiceRef.id}`);
                props.history.push(`/admin/billing/view/${this.state.patientId}`)
            }).catch((e) => {
                alert(`Error Occurred: ${e.stack}`);
            });
        }.bind(this);
    }

    render() {
        let amount = 0;
        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title={`Add Bill (${this.state.patientDetails.name || "Loading"})`}
                                content={
                                    <div>
                                        <Select
                                            name="services"
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            options={Object.keys(this.state.products).map((id) => {
                                                return {value: id, label: this.state.products[id].name};
                                            })}
                                            onChange={(e) => {
                                                this.addProduct(e.value)
                                            }}
                                        />
                                        <Table striped hover>
                                            <thead>
                                            <tr>
                                                {billThArray.map((prop, key) => {
                                                    return <th key={key}>{prop}</th>;
                                                })}
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {Object.keys(this.state.invoice).map((id, sno) => {
                                                amount += this.state.products[id] ? this.state.products[id].rate * this.state.invoice[id].qty : 0;
                                                return (
                                                    <tr key={id}>
                                                        <td key={0}>{sno + 1}</td>
                                                        <td key={1}>{this.state.products[id] ? this.state.products[id].name : "Loading"}</td>
                                                        <td key={2}>{this.state.products[id] ? this.state.products[id].rate : "Loading"}</td>
                                                        <td key={3}>
                                                            {
                                                                this.state.invoice[id].type === 'service' ?
                                                                    this.state.invoice[id].qty
                                                                    :
                                                                    <Input
                                                                        type="number"
                                                                        onChange={(e) => {
                                                                            this.changeQty(id, e.target.value)
                                                                        }}
                                                                        value={this.state.invoice[id].qty}
                                                                    />
                                                            }
                                                        </td>
                                                        <td key={4}>{this.state.products[id] ? this.state.products[id].rate * this.state.invoice[id].qty : "Loading"}</td>
                                                        <td key={4}><Button onClick={() => {
                                                            this.remove(id)
                                                        }}>Remove</Button></td>
                                                    </tr>
                                                );
                                            })}
                                            <tr>
                                                <td key={0}/>
                                                <td key={1}/>
                                                <td key={2}/>
                                                <td key={3}>Total Amount</td>
                                                <td key={4}>{amount} INR</td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                        <Button onClick={this.generateBill} bsStyle="info" pullRight fill>
                                            Save bill
                                        </Button>
                                        <div className="clearfix"/>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default withRouter(AddBill);
