import React, {Component} from "react";
import {Col, ControlLabel, Grid, Row} from "react-bootstrap";

import {Card} from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Input from "@material-ui/core/Input";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import firebase from "firebase";

class Services extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,
            name: '',
            description: '',
            price: 0,
        };
        this.hospitalRef = firebase.firestore().collection('hospitals').doc(localStorage.getItem('hospitalId'));
        this.hospitalRef.collection('services').doc(this.state.id).get().then((service)=>{
            this.setState({...service.data()});
        });
        this.submit = this.submit.bind(this);
    }

    submit() {
        let {name, description, price} = this.state;
        if (!name || name === '') {
            alert('Fill Name');
            return;
        }
        if (!price || price === 0) {
            alert('Fill Price');
            return;
        }
        if (!description || description === '') {
            alert('Fill Description');
            return;
        }
        this.hospitalRef.collection('services').doc(this.state.id).set({
            name, description, price
        }, {merge: true}).then(() => {
            alert('Successfully Updated');
            this.props.history.push(`/admin/services`);
        }).catch((e) => {
            alert(`Error while updating Service: ${e.stack}`);
        })

    }

    render() {
        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title="Add Services"
                                content={
                                    <div>
                                        <Row>
                                            <div className="col-md-12">
                                                <ControlLabel>Name</ControlLabel><br/>
                                                <Input
                                                    value={this.state.name}
                                                    onChange={(e) => {
                                                        this.setState({name: e.target.value})
                                                    }}
                                                    fullWidth
                                                    type="text"
                                                    placeholder="Service Name"
                                                />
                                            </div>
                                        </Row>
                                        <Row>
                                            <div className="col-md-4">
                                                <ControlLabel>Price</ControlLabel><br/>
                                                <Input
                                                    value={this.state.price}
                                                    onChange={(e) => {
                                                        this.setState({price: e.target.value})
                                                    }}
                                                    fullWidth
                                                    type="number"
                                                    placeholder="Service Price"
                                                />
                                            </div>
                                        </Row>
                                        <Row>
                                            <div className="col-md-12">
                                                <ControlLabel>Details</ControlLabel><br/>
                                                <TextareaAutosize
                                                    value={this.state.description}
                                                    onChange={(e) => {
                                                        this.setState({description: e.target.value})
                                                    }}
                                                    style={{height: 150, width: '100%'}}
                                                    type="textarea"
                                                    placeholder="Service Description"
                                                />
                                            </div>
                                        </Row>
                                        <Button
                                            bsStyle="info"
                                            pullRight
                                            fill
                                            onClick={this.submit}
                                        >
                                            Add Service
                                        </Button>
                                        <div className="clearfix"/>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default Services;
